import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo.png';
import axios from 'axios';

import "./Register.css";

function Register({ onRegister }) {
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post('/api/auth/register', {
                userName,
                userEmail,
                password,
            });

            if (response.data.success) {
                onRegister();
                setSuccess("Account created successfully!");
                setError(null);
                setTimeout(() => {
                    sessionStorage.setItem('isAuthenticated', 'true');
                    sessionStorage.setItem('userEmail', response.data.data.email);
                    sessionStorage.setItem('username', response.data.data.username);
                    navigate('/guides')
                }, 2000); // Redirect after success

            } else {
                setError(response.data.message || "Registration failed");
            }
        } catch (error) {
            setError(error.response?.data?.message || "Server error occurred");
            setSuccess(null);
        }
    };

    return (
        <div className="registerScreen">
            <img src={logo} className="logo" alt="logo" />
            <div className="registerCard">
                <h1 className='registerHeader'>Register</h1>
                <form className="registerForm" onSubmit={handleSubmit}>
                    <input className="registerTextField" type="text" placeholder="Username" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                    <input className="registerTextField" type="email" placeholder="Email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
                    <input className="registerTextField" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <input className="registerTextField" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                    <button className="registerCreateButton" type="button" onClick={() => navigate('/login')}>Already have an account?</button>
                    <button className="registerSubmitButton" type="submit">Create Your Account</button>
                </form>
            </div>
        </div>
    );
}

export default Register;