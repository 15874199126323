import React, { useState, useEffect } from 'react';
import axios from 'axios';

import cashIcon from '../../assets/sprite_0.png';
import goldIcon from '../../assets/sprite_1.png';

import './profileBar.css';

function ProfileBar() {

    const [username] = useState(() => {
        return sessionStorage.getItem('username');
    });

    const [level, setLevel] = useState(0);
    const [xp, setXP] = useState(0);
    const [region, setRegion] = useState(0);
    const [cash, setCash] = useState(0);
    const [gold, setGold] = useState(0);

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in sessionStorage");
            return;
        }

        try {
            const response = await axios.get(`/api/menu/header?username=${username}`);
            if (response.data.success) {
                setLevel(response.data.data.playerLvl);
                setXP(response.data.data.playerExp);
                setRegion(response.data.data.regionNo);
                setCash(response.data.data.regionCash);
                setGold(response.data.data.premium);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className="profileBar">
            <div className="experienceCard">
                <div className="experienceCardRight">
                    <div className="experienceHeaderText">Level {level}</div>
                    <div className="progressXPBase">
                        <div className="progressXP" style={{ width: `${xp}%` }}>.</div>
                    </div>
                </div>
            </div>
            <div className="cashCard">
                <img className="navButton_Icon" src={cashIcon} alt="Home" />
                <div className="cashCardRight">
                    <div className="cashHeaderText">Region {region} Cash</div>
                    <div className="cashText">{cash.toFixed(2)}</div>
                </div>
            </div>
            <div className="goldCard">
                <img className="navButton_Icon" src={goldIcon} alt="Home" />
                <div className="goldCardRight">
                    <div className="goldHeaderText">Gold</div>
                    <div className="goldText">{gold}</div>
                </div>
            </div>
        </div>
    );
}

export default ProfileBar;