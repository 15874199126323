import './home.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';
import AdventureCard from '../../components/adventureCard/adventureCard.js';

function Home() {

    return (
        <div className="homeScreen">
            <ProfileBar />
            <a href="/event" className="eventCard">
                <h1>Season 1</h1>
            </a>
            <AdventureCard />
            <a href="/battle" className="battleCard">
                <h1>Battles</h1>
            </a>
            <a href="/kitchen" className="kitchenCard">
                <h1>Kitchen</h1>
            </a>
            <a href="/order" className="orderCard">
                <h1>Orders</h1>
            </a>
            <a href="/quest" className="questCard">
                <h1>Quests</h1>
            </a>
            <a href="/headquarters" className="hqHomeCard">
                <h1>Headquarters</h1>
            </a>
            <a href="/guides" className="guidesHomeCard">
                <h1>Guides</h1>
            </a>
            <NavBar />
        </div>
    );
}

export default Home;