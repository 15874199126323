import { useState, useEffect } from 'react';
import axios from 'axios';
import './headquarters.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

import iconToken from '../../assets/items/adventoken.png';
import iconKey from '../../assets/items/key.png';

import iconIng1 from '../../assets/ingredients/01_Chicken.png';

let icons = {
    "ITEM1": iconToken,
    "ITEM2": iconKey,
    "INGR1": iconIng1,
    "PR1-1": iconToken
}
function Headquarters() {
    const [username] = useState(() => sessionStorage.getItem("username"));
    const [items, setItems] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [parcels, setParcels] = useState([]);

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.get(`/api/inventory/getItems?username=${username}`);
            if (response.data.success) {
                setItems(response.data.items);
                setIngredients(response.data.ingredients);
                setParcels(response.data.parcels);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const openParcel = async (parcelIndex) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/headquarters/openParcels`, { username, parcelIndex });
            if (response.data.success) {
               alert("You opened one of your parcels: \n" + response.data.reward)
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    }

    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className="hqScreen">
            <ProfileBar />
            <div className="hqHeader">Headquarters</div>
            <div className="hqCard">
                <h3>Parcels (Region 1)</h3>
                <div className="hqCategory">
                    {parcels.map((item, index) => (
                        <button key={index} className="hq-item" onClick={() => openParcel(index)}>
                            <img src={icons[item.icon]} alt={item.name} className="item-icon" />
                            <div className="item-info">
                                <span className="item-name">{item.name}</span>
                                <span className="item-quantity">x{item.quantity}</span>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <NavBar />
        </div>
    );
}

export default Headquarters;
