import { useState, useEffect } from 'react';
import axios from 'axios';
import './profile.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

function Profile() {

    // Profile States
    const [username] = useState(() => sessionStorage.getItem("username"));
    const [dateJoined, setDateJoined] = useState("");

    // Adventure States
    const [advLevel, setAdvLevel] = useState(0);
    const [advProgress, setAdvProgress] = useState(0);
    const [xp, setXP] = useState(0);
    const [idleDMG, setIdleDMG] = useState(0);

    // Regional States
    const [repLevel, setRepLevel] = useState(0);
    const [repProgress, setRepProgress] = useState(0);
    const [stars, setStars] = useState(0);

    // Equipment
    const [helmLevel, setHelmLevel] = useState(0);
    const [helmStats, setHelmStats] = useState(0);
    const [suitLevel, setSuitLevel] = useState(0);
    const [suitStats, setSuitStats] = useState(0);
    const [bootLevel, setBootLevel] = useState(0);
    const [bootStats, setBootStats] = useState(0);
    const [weapLevel, setWeapLevel] = useState(0);
    const [weapStats, setWeapStats] = useState(0);

    // Gems
    const [waterLevel, setWaterLevel] = useState(0);
    const [waterStats, setWaterStats] = useState(0);
    const [fireLevel, setFireLevel] = useState(0);
    const [fireStats, setFireStats] = useState(0);
    const [earthLevel, setEarthLevel] = useState(0);
    const [earthStats, setEarthStats] = useState(0);
    const [windLevel, setWindLevel] = useState(0);
    const [windStats, setWindStats] = useState(0);

    // Monthly Stats
    const [cluckyStat, setCluckyStat] = useState(0);

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.get(`/api/profile/getInfo?username=${username}`);
            if (response.data.success) {
                setDateJoined(response.data.profile.date);

                setAdvLevel(response.data.adventure.level);
                setAdvProgress(response.data.adventure.progress);
                setXP(response.data.adventure.xp);
                setIdleDMG(response.data.adventure.idleDMG);

                setRepLevel(response.data.regional.level);
                setRepProgress(response.data.regional.progress);
                setStars(response.data.regional.stars);

                setHelmLevel(response.data.equipment.helmLvl);
                setHelmStats(response.data.equipment.helmStat);
                setSuitLevel(response.data.equipment.suitLvl);
                setSuitStats(response.data.equipment.suitStat);
                setBootLevel(response.data.equipment.bootLvl);
                setBootStats(response.data.equipment.bootStat);
                setWeapLevel(response.data.equipment.weapLvl);
                setWeapStats(response.data.equipment.weapStat);

                setWaterLevel(response.data.gems.waterLvl);
                setWaterStats(response.data.gems.waterStat);
                setFireLevel(response.data.gems.fireLvl);
                setFireStats(response.data.gems.fireStat);
                setEarthLevel(response.data.gems.earthLvl);
                setEarthStats(response.data.gems.earthStat);
                setWindLevel(response.data.gems.windLvl);
                setWindStats(response.data.gems.windStat);

                setCluckyStat(response.data.statistics.adventure.clucky);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };
    // Fetch inventory data
    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className="profileScreen">
            <ProfileBar />
            <div className="tempCard">
                <h1>Profile</h1>
                <p className="profile-text">Username: {username}</p>
                <p className="profile-text">Date Joined: {dateJoined}</p>
            </div>
            <a href="/adventure" className="tempCard">
                <h1>Adventure</h1>
                <p className="profile-text">Adventure Level: {advLevel}</p>
                <div className="progressBarBase">
                    <div className="advXP" style={{ width: `${advProgress}%` }}>.</div>
                </div>
                <p className="profile-text">Total EXP: {xp}</p>
                <p className="profile-text">IdleDMG: {idleDMG}</p>
            </a>
            <div className="tempCard">
                <h1>Region</h1>
                <p className="profile-text">Reputation Level: {repLevel}</p>
                <div className="progressBarBase">
                    <div className="repXP" style={{ width: `${repProgress}%` }}>.</div>
                </div>
                <p className="profile-text">Total Stars: {stars}</p>
            </div>
            <a href="/equipment" className="tempCard">
                <h1>Equipment</h1>
                <div className="profile-item">
                    <h2>Helmet</h2>
                    <span className="profile-text">Level: {helmLevel}</span>
                    <span className="profile-text">HP: {helmStats}</span>
                </div>
                <div className="profile-item">
                    <h2>Suit</h2>
                    <span className="profile-text">Level: {suitLevel}</span>
                    <span className="profile-text">DEF: {suitStats}</span>
                </div>
                <div className="profile-item">
                    <h2>Boots</h2>
                    <span className="profile-text">Level: {bootLevel}</span>
                    <span className="profile-text">HP: {bootStats}</span>
                </div><div className="profile-item">
                    <h2>Weapon</h2>
                    <span className="profile-text">Level: {weapLevel}</span>
                    <span className="profile-text">Base DMG: {weapStats}</span>
                </div>
            </a>
            <a href="/gems" className="tempCard">
                <h1>Gems</h1>
                <div className="profile-item">
                    <h2>Water</h2>
                    <span className="profile-text">Level: {waterLevel}</span>
                    <span className="profile-text">Water DMG: {waterStats}</span>
                </div>
                <div className="profile-item">
                    <h2>Fire</h2>
                    <span className="profile-text">Level: {fireLevel}</span>
                    <span className="profile-text">Fire DMG: {fireStats}</span>
                </div>
                <div className="profile-item">
                    <h2>Earth</h2>
                    <span className="profile-text">Level: {earthLevel}</span>
                    <span className="profile-text">Earth DMG: {earthStats}</span>
                </div><div className="profile-item">
                    <h2>Wind</h2>
                    <span className="profile-text">Level: {windLevel}</span>
                    <span className="profile-text">Wind DMG: {windStats}</span>
                </div>
            </a>
            <a href="/statistics" className="tempCard">
                <h1>Region 1 Statistics</h1>
                <div className="profile-item">
                    <h2>Adventure</h2>
                    <span className="profile-text">Clucky: {cluckyStat}</span>
                </div>
            </a>
            <NavBar />
        </div>
    );
}

export default Profile;