import logo from './assets/logo.png';
import NavBar from './components/navBar/navBar';
import './Maintenance.css';

function Maintenance() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>Battlecook</code> is currently on maintenance.
        </p>
        <button className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Visit our Discord for more Information
        </button>
      </header>
      <NavBar />
    </div>
  );
}

export default Maintenance;
