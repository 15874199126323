import './adventure.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

import zone01 from '../../assets/01_Clucky.png';
import zone02 from '../../assets/02_Garly.png';
import zone03 from '../../assets/03_Crabby.png';
import zone04 from '../../assets/04_Chilly.png';
import zone05 from '../../assets/05_Prawnie.png';
import zone06 from '../../assets/06_Beany.png';
import zone07 from '../../assets/07_Piggy.png';
import zone08 from '../../assets/08_Salty.png';
import zone09 from '../../assets/09_Fishy.png';
import zone10 from '../../assets/10_Tommatoes.png';

import { useEffect, useState } from 'react';
import axios from 'axios';

function Adventure() {
    let regionNames = ["Unavailable", "1: Singapore"];
    let zoneNames = ["Unavailable", "The Clucky Land (Zone 1)"];

    // Player Info
    const [username] = useState(() => {
        return sessionStorage.getItem("username");
    });

    // Adventure Status Information
    const [region, setRegion] = useState(1);
    const [zone, setZone] = useState(0);
    const [idleDMG, setIdleDMG] = useState(0);
    const [timePerKill, setTimePerKill] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [estKill, setEstKill] = useState(0);
    const [maxTime, setMaxTime] = useState(0);

    // Handle Claim button click
    const handleClaim = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.put(`/api/adventure/claim`, { username, zone });
            if (response.data.success) {
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill(response.data.data.timeElapsed);

                alert(`You have collected your rewards! \nTotal Kills: ${response.data.reward.kills} \nTotal Cash: ${response.data.reward.cash} \nTotal XP: ${response.data.reward.xp} \nTotal Ingredients: ${response.data.reward.ingredient} \nDungeon Key: ${response.data.reward.key} \nRegion 1 Parcel (Tier 1): ${response.data.reward.parcel} \nRegion 1 Token: ${response.data.reward.token} `)
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
        }
    };

    const handleJoin = async (zoneSelected) => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.put(`/api/adventure/enter`, { username, zone: zoneSelected });
            if (response.data.success) {
                setRegion(response.data.data.RegionNo);
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill(response.data.data.timePerKill);
                setTimeElapsed(response.data.data.timeElapsed);
                setEstKill(Math.floor(timeElapsed / timePerKill));
                setMaxTime(response.data.data.maxTime);
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
        }
    };

    // Update the data at initial load (Once)
    const fetchAPIData = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.get(`/api/adventure/status?username=${username}`);
            if (response.data.success) {
                setRegion(response.data.data.regionNo);
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill(response.data.data.timePerKill);
                setTimeElapsed(response.data.data.timeElapsed);
                setEstKill(Math.floor(timeElapsed / timePerKill));
                setMaxTime(response.data.data.maxTime);
            }
        } catch {
            console.log("error");
        }
    };

    useEffect(() => {
        fetchAPIData();
    }, [username]);

    // UseEffect for the live updating progress bar and idleKills update
    useEffect(() => {
        const interval = setInterval(() => {
            // Only update time if the player is in a valid zone
            if (zone > 0) {  // Only count time if in a valid zone (zone > 0)
                setTimeElapsed(prevTime => {
                    if (prevTime < maxTime) {  // Update until 2 hours (Idle capped)
                        return prevTime + 1;
                    }
                    clearInterval(interval); // Stop interval once we reach the capped time
                    return prevTime;
                });
            } else {
                // If the player is in an invalid zone (zone == 0), set time to 0
                setTimeElapsed(0);
            }

            // Update idleKills based on mobCounter function
            setEstKill(prevKills => {
                if (timeElapsed >= maxTime) {
                    return Math.floor(maxTime / timePerKill);
                } else if (timeElapsed === 0) {
                    return 0;
                } else {
                    return Math.floor(timeElapsed / timePerKill);
                }
            });

        }, 1000); // Runs every second

        // Cleanup on component unmount
        return () => clearInterval(interval);
    }, [timeElapsed, zone, idleDMG, maxTime, timePerKill]);  // Dependencies are time, zone, and idleDmg

    function timeFormatter(seconds) {
        if (seconds === 0) {
            return "No Adventure Ongoing"
        } else if (seconds < 60) {
            return "0:" + ((seconds < 10) ? "0" + seconds : seconds);
        } else if (seconds < (3600)) {
            return Math.floor(seconds / 60) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else if (seconds < maxTime) {
            return Math.floor(seconds / 3600) + ":" + (((Math.floor(seconds % 3600 / 60)) < 10) ? "0" + Math.floor(seconds % 3600 / 60) : Math.floor(seconds % 3600 / 60)) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else {
            return Math.floor(maxTime / 3600) + "h (Idle Capped)";
        }
    }

    return (
        <div className="adventureScreen">
            <ProfileBar />
            <div className="adventureHeader">Adventure</div>
            <div className="adventureStatusCard">
                <h3>Current Status</h3>
                <div className="statusInfoText">Region {regionNames[region]}</div>
                <div className="statusInfoText">Zone: {zoneNames[zone]}</div>
                <div className="statusInfoText">Idle DMG: {idleDMG}</div>
                <div className="statusInfoText">Idle Kills: {estKill}</div>
                <div className="statusInfoText">Est. Kill Time: {timePerKill}s</div>
                <div className="statusInfoText">Progress:</div>
                <div className="progressBar">
                    <div className="progressIdle" style={{ width: (timeElapsed / maxTime >= 1 || timeElapsed == 0) ? "100%" : `${(timeElapsed * 100) / maxTime}%`, backgroundColor: (timeElapsed / maxTime >= 1 || timeElapsed == 0) ? "#FF0000" : "",  color: (timeElapsed / maxTime >= 1 || timeElapsed == 0) ? "#FFFFFF" : ""  }}>{timeFormatter(timeElapsed)}</div>
                </div>
                {(zone !== 0) ? <button className="joinZoneButton" onClick={handleClaim} type="button">Claim Rewards</button> : ""}
            </div>
            <div className="adventureZoneCard">
                <h3>Region 1 Zones</h3>
                <div className="adventureZoneList">
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 1: The Clucky Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone01} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 1 </span>
                                <span className="enemyInfoText">Enemy's DEF: 1 </span>
                                <span className="enemyInfoText">DMG Required: 1+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => handleJoin(1)} type="button">Enter Zone 1</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 2: The Garly Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone02} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 5 </span>
                                <span className="enemyInfoText">Enemy's DEF: 5 </span>
                                <span className="enemyInfoText">DMG Required: 5+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 3: The Crabby Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone03} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 10 </span>
                                <span className="enemyInfoText">Enemy's DEF: 10 </span>
                                <span className="enemyInfoText">DMG Required: 10+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 4: The Chilly Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone04} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 15 </span>
                                <span className="enemyInfoText">Enemy's DEF: 15 </span>
                                <span className="enemyInfoText">DMG Required: 15+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 5: The Prawnie Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone05} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 20 </span>
                                <span className="enemyInfoText">Enemy's DEF: 20 </span>
                                <span className="enemyInfoText">DMG Required: 20+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 6: The Beany Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone06} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 25 </span>
                                <span className="enemyInfoText">Enemy's DEF: 25 </span>
                                <span className="enemyInfoText">DMG Required: 25+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 7: The Piggy Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone07} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 30 </span>
                                <span className="enemyInfoText">Enemy's DEF: 30 </span>
                                <span className="enemyInfoText">DMG Required: 30+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 8: The Salty Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone08} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 35 </span>
                                <span className="enemyInfoText">Enemy's DEF: 35 </span>
                                <span className="enemyInfoText">DMG Required: 35+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 9: The Fishy Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone09} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 40 </span>
                                <span className="enemyInfoText">Enemy's DEF: 40 </span>
                                <span className="enemyInfoText">DMG Required: 40+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                    <div className="adventureZoneItem">
                        <div className="adventureZoneHeader">Zone 10: The Tommatoes Land</div>
                        <div className="adventureText">
                            <img className="zoneIcon" src={zone10} alt="Home" />
                            <div className="enemyInfo">
                                <span className="enemyInfoText">Enemy's HP: 45 </span>
                                <span className="enemyInfoText">Enemy's DEF: 45 </span>
                                <span className="enemyInfoText">DMG Required: 45+ </span>
                            </div>
                        </div>
                        {(zone === 0) ? <button className="joinZoneButton" onClick={() => alert("Locked")} type="button">Locked</button> : ""}
                    </div>
                </div>
            </div>
            <NavBar />
        </div >
    );
}

export default Adventure;
