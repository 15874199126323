import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './equipment.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

import blank from '../../assets/items/adventoken.png';


function Equipment() {

    const navigate = useNavigate();
    const [username] = useState(() => sessionStorage.getItem("username"));

    // Inventory
    const [inventory, setInventory] = useState({});

    // Equipment
    const [equipments, setEquipments] = useState({
        helmet: { level: 0, stats: 0, points: 0 },
        suit: { level: 0, stats: 0, points: 0 },
        boots: { level: 0, stats: 0, points: 0 },
        weapon: { level: 0, stats: 0, points: 0 }
    });

    // Gems
    const [gems, setGems] = useState({
        water: { level: 0, stats: 0, points: 0 },
        fire: { level: 0, stats: 0, points: 0 },
        earth: { level: 0, stats: 0, points: 0 },
        wind: { level: 0, stats: 0, points: 0 }
    });

    const upgradeHelm = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeHelm`, { username });
            if (response.data.success) {
                alert(`Forge Complete! Your Helmet is strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const upgradeSuit = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeSuit`, { username });
            if (response.data.success) {
                alert(`Forge Complete! Your Suit is strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const upgradeBoot = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeBoot`, { username });
            if (response.data.success) {
                alert(`Forge Complete! Your Boots are strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const upgradeWeap = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeWeap`, { username });
            if (response.data.success) {
                alert(`Forge Complete! Your Weapon is strengthened!`);
                navigate('/equipment');
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const upgradeWater = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeWater`, { username });
            if (response.data.success) {
                navigate('/equipment');
                alert(`Polish Complete! Your Water Gem is strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const upgradeFire = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeFire`, { username });
            if (response.data.success) {
                navigate('/equipment');
                alert(`Polish Complete! Your Fire Gem is strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };


    const upgradeEarth = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeEarth`, { username });
            if (response.data.success) {
                navigate('/equipment');
                alert(`Polish Complete! Your Earth Gem is strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const upgradeWind = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/equipment/upgradeWind`, { username });
            if (response.data.success) {
                navigate('/equipment');
                alert(`Polish Complete! Your Wind Gem is strengthened!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough materials to forge the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.get(`/api/equipment/getInfo?username=${username}`);
            if (response.data.success) {
                setInventory(response.data.inventory);
                setEquipments(response.data.equipment);
                setGems(response.data.gems);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };
    // Fetch inventory data
    useEffect(() => {
        fetchAPIData();
    }, [username]);

    return (
        <div className="profileScreen">
            <ProfileBar />
            <div className="inventoryHeader">Equipments</div>
            <div href="/equipment" className="equipStatCard">
                <h1>Player Stats</h1>
                <div className="equip-stat-1">
                    <h2>Health</h2>
                    <span className="equip-stat-text">{equipments.helmet.stats + equipments.boots.stats} HP</span>
                </div>
                <div className="equip-stat-1">
                    <h2>Defence</h2>
                    <span className="equip-stat-text">{equipments.suit.stats} DEF</span>
                </div>
                <div className="equip-stat-1">
                    <h2>Base Weapon</h2>
                    <span className="equip-stat-text">{equipments.weapon.stats} DMG</span>
                </div>
                <div className="equip-stat-2">
                    <h2>Water Gem</h2>
                    <span className="equip-stat-text">+{gems.water.stats} DMG</span>
                </div>
                <div className="equip-stat-2">
                    <h2>Fire Gem</h2>
                    <span className="equip-stat-text">+{gems.fire.stats} DMG</span>
                </div>
                <div className="equip-stat-2">
                    <h2>Earth Gem</h2>
                    <span className="equip-stat-text">+{gems.earth.stats} DMG</span>
                </div>
                <div className="equip-stat-2">
                    <h2>Wind Gem</h2>
                    <span className="equip-stat-text">+{gems.wind.stats} DMG</span>
                </div>

            </div>
            <div href="/equipment" className="equipStatCard">
                <h1>Equipment</h1>
                <div className="equipment-item">
                    <h2>Helmet</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {equipments.helmet.level} - {equipments.helmet.stats} HP</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="equipment-xp" style={{ width: `${equipments.helmet.points}%` }}>{equipments.helmet.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Helmet Blueprint" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Helmet Blueprint</span>
                                <span className="item-quantity">{inventory.helmBP}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Equipment Material" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Equipment Material</span>
                                <span className="item-quantity">{inventory.material}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Forge Fuel" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Forge Fuel</span>
                                <span className="item-quantity">{inventory.fuel}/1</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeHelm()}>Upgrade Helmet</button>
                    </div>
                </div>
                <div className="equipment-item">
                    <h2>Suit</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {equipments.suit.level} - {equipments.suit.stats} DEF</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="equipment-xp" style={{ width: `${equipments.suit.points}%` }}>{equipments.suit.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Suit Blueprint" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Suit Blueprint</span>
                                <span className="item-quantity">{inventory.suitBP}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Equipment Material" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Equipment Material</span>
                                <span className="item-quantity">{inventory.material}/10</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Forge Fuel" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Forge Fuel</span>
                                <span className="item-quantity">{inventory.fuel}/2</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeSuit()}>Upgrade Suit</button>
                    </div>
                </div>
                <div className="equipment-item">
                    <h2>Boots</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {equipments.boots.level} - {equipments.boots.stats} HP</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="equipment-xp" style={{ width: `${equipments.boots.points}%` }}>{equipments.boots.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Boots Blueprint" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Boots Blueprint</span>
                                <span className="item-quantity">{inventory.bootBP}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Equipment Material" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Equipment Material</span>
                                <span className="item-quantity">{inventory.material}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Forge Fuel" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Forge Fuel</span>
                                <span className="item-quantity">{inventory.fuel}/1</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeBoot()}>Upgrade Boots</button>
                    </div>
                </div>
                <div className="equipment-item">
                    <h2>Weapon</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {equipments.weapon.level} - {equipments.weapon.stats} DMG</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="equipment-xp" style={{ width: `${equipments.weapon.points}%` }}>{equipments.weapon.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Weapon Blueprint" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Weapon Blueprint</span>
                                <span className="item-quantity">{inventory.weapBP}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Equipment Material" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Equipment Material</span>
                                <span className="item-quantity">{inventory.material}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Forge Fuel" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Forge Fuel</span>
                                <span className="item-quantity">{inventory.fuel}/2</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeWeap()}>Upgrade Weapon</button>
                    </div>
                </div>
            </div>
            <div href="/gems" className="equipStatCard">
                <h1>Gems</h1>
                <div className="equipment-item">
                    <h2>Water Gem</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {gems.water.level} - {gems.water.stats} DMG</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="water equipment-xp" style={{ width: `${gems.water.points}%` }}>{gems.water.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Water Gem Shard" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Water Gem Shard</span>
                                <span className="item-quantity">{inventory.waterShard}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Gem Dust" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Gem Dust</span>
                                <span className="item-quantity">{inventory.dust}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Polishing Cloth" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Polishing Cloth</span>
                                <span className="item-quantity">{inventory.cloth}/1</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeWater()}>Upgrade Water Gem</button>
                    </div>
                </div>
                <div className="equipment-item">
                    <h2>Fire Gem</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {gems.fire.level} - {gems.fire.stats} DMG</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="fire equipment-xp" style={{ width: `${gems.fire.points}%` }}>{gems.fire.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Fire Gem Shard" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Fire Gem Shard</span>
                                <span className="item-quantity">{inventory.fireShard}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Gem Dust" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Gem Dust</span>
                                <span className="item-quantity">{inventory.dust}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Polishing Cloth" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Polishing Cloth</span>
                                <span className="item-quantity">{inventory.cloth}/1</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeFire()}>Upgrade Fire Gem</button>
                    </div>
                </div>
                <div className="equipment-item">
                    <h2>Earth Gem</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {gems.earth.level} - {gems.earth.stats} DMG</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="earth equipment-xp" style={{ width: `${gems.earth.points}%` }}>{gems.earth.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Earth Gem Shard" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Earth Gem Shard</span>
                                <span className="item-quantity">{inventory.earthShard}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Gem Dust" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Gem Dust</span>
                                <span className="item-quantity">{inventory.dust}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Polishing Cloth" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Polishing Cloth</span>
                                <span className="item-quantity">{inventory.cloth}/1</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeEarth()}>Upgrade Earth Gem</button>
                    </div>
                </div>
                <div className="equipment-item">
                    <h2>Wind Gem</h2>
                    <div className="equipment-item-header">
                        <span className="equipment-item-text">Level {gems.wind.level} - {gems.wind.stats} DMG</span>
                    </div>
                    <div className="equipment-bar">
                        <div className="wind equipment-xp" style={{ width: `${gems.wind.points}%` }}>{gems.wind.points}%</div>
                    </div>
                    <div className="equipment-item-upgrade">
                        <span className="equipment-item-text">Upgrade Materials Required</span>
                        <div className="upgrade-item">
                            <img src={blank} alt="Wind Gem Shard" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Wind Gem Shard</span>
                                <span className="item-quantity">{inventory.windShard}/1</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Gem Dust" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Gem Dust</span>
                                <span className="item-quantity">{inventory.dust}/5</span>
                            </div>
                        </div>
                        <div className="upgrade-item">
                            <img src={blank} alt="Polishing Cloth" className="item-icon" />
                            <div className="upgrade-item-info">
                                <span className="item-name">Polishing Cloth</span>
                                <span className="item-quantity">{inventory.cloth}/1</span>
                            </div>
                        </div>
                        <button className="upgradeButton" type="button" onClick={() => upgradeWind()}>Upgrade Wind Gem</button>
                    </div>
                </div>
            </div>
            <NavBar />
        </div>
    );
}

export default Equipment;