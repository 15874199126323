import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './battle.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

function Battle() {
    const navigate = useNavigate();
    const [username] = useState(() => { return sessionStorage.getItem("username") });

    const enterDungeon = async () => {
        try {
            const response = await axios.post(`/api/dungeon/create`, { username });
            if (response.data.success) {
                navigate("/battlefield");
            } else if (response.status == 201) {
                alert("You do not have Dungeon 1 Ticket to enter!");
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
        }
    };

    return (
        <div className="battleScreen">
            <div className="battleHeader">Battles</div>
            <ProfileBar />
            <div class="dungeon">
                <h2>Dungeon</h2>
                <h3>Defeat the Regional Boss for Special Ingredients!</h3>
                <h3>Entry Fee: 1 x Dungeon 1 Key</h3>
                <button className="joinDungeon" onClick={() => enterDungeon()}>Enter the Dungeon...</button>
            </div>
            <div class="arena">
                <h2>Arena</h2>
                <h3>Use your skills to defeat other chefs!</h3>
                <h3>Coming Soon!</h3>
            </div>
            <NavBar />
        </div>
    );
}

export default Battle;
