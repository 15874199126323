import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './assets/logo.png';
import './Login.css';

function Login({ onLogin }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page reload on form submission
    console.log('Logging in...');

    try {
      // Call the backend login API
      const response = await loginUser(email, password);
      if (response.success) {
        onLogin(); // Call the parent component's onLogin if successful
        setIsAuthenticated(true);
        console.log(response.data);
        sessionStorage.setItem('isAuthenticated', 'true');
        sessionStorage.setItem('userEmail', response.data.email);
        sessionStorage.setItem('username', response.data.username);
        setError(null);
        navigate('/home');
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("An error occurred during login.");
    }
  };

  async function loginUser(email, password) {
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      return response.data;
    } catch (error) {
      return { success: false, message: 'Login failed.' };
    }
  } 
  
  return (
    <div className="loginScreen">
      <img src={logo} className="logo" alt="logo" />
      <div className="loginCard">
        <div className="header">Login</div>
        <form className="loginForm" onSubmit={handleSubmit}>
          <input className="loginTextField" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
          <input className="loginTextField" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}  required />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {!isAuthenticated && <p style={{ color: 'black' }}> </p>}
          <button className="createButton" href='/register' onClick={() => navigate('/register')} type="button">Create Account</button>
          <button className="loginButton" type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
