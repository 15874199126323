import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './market.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';


function Market() {
    const navigate = useNavigate();

    const [username] = useState(() => sessionStorage.getItem("username"));

    const buyFuel = async (quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buyFuel`, { username, quantity });
            if (response.data.success) {
                alert(`Thank you for your purchase! We have added ${quantity} Forge Fuel into your inventory!`);
                navigate("/market");
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const buyCloth = async (quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buyCloth`, { username, quantity });
            if (response.data.success) {
                alert(`Thank you for your purchase! We have added ${quantity} Polishing Cloth into your inventory!`);
                navigate("/market");
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const buyParcelR1T1 = async (quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buyParcelR1T1`, { username, quantity });
            if (response.data.success) {
                alert(`Thank you for your purchase! We have added ${quantity} Region 1 (Tier 1) Parcel into your inventory!`);
                navigate("/market");
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    // const fetchAPIData = async () => {
    //     if (!username) {
    //         console.error("Username not found in session.");
    //         return;
    //     }

    //     try {
    //         const response = await axios.get(`/api/profile/getInfo?username=${username}`);
    //         if (response.data.success) {
    //         }
    //     } catch (error) {
    //         console.error("Error fetching inventory data:", error);
    //     }
    // };
    // // Fetch inventory data
    // useEffect(() => {
    //     fetchAPIData();
    // }, [username]);

    return (
        <div className="profileScreen">
            <ProfileBar />
            <div className="inventoryHeader">Supermarket</div>
            <div href="/equipment" className="tempCard">
                <h1>Equipment Materials</h1>
                <div className="market-1">
                    <h2>Forge Fuel</h2>
                    <button onClick={() => buyFuel(1)} className="equip-stat-text">Buy 1 @ 10.00 SGD</button>
                </div>
                <div className="market-1">
                    <h2>Polishing Cloth</h2>
                    <button onClick={() => buyCloth(1)} className="equip-stat-text">Buy 1 @ 20.00 SGD</button>
                </div>
            </div>
            <div href="/equipment" className="tempCard">
                <h1>Parcels (Region 1)</h1>
                <div className="market-1">
                    <h2>Region 1 (Tier 1)</h2>
                    <button onClick={() => buyParcelR1T1(1)} className="equip-stat-text">Buy 1 @ 50.00 SGD</button>
                </div>
            </div>
            <NavBar />
        </div>
    );
}

export default Market;