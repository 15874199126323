import { useState, useEffect } from 'react';
import axios from 'axios';
import './inventory.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

import iconToken from '../../assets/items/adventoken.png';
import iconKey from '../../assets/items/key.png';

import iconIng1 from '../../assets/ingredients/01_Chicken.png';

import iconSpec1 from '../../assets/ingredients/11_ChickenStock.png';
import iconSpec2 from '../../assets/ingredients/12_SteamedBun.png';
import iconSpec3 from '../../assets/ingredients/13_FriedLard.png';
import iconSpec4 from '../../assets/ingredients/14_FriedFritter.png';
import iconSpec5 from '../../assets/ingredients/15_CoconutMilk.png';

let icons = {
    "ITEM1": iconToken,
    "ITEM2": iconKey,
    "INGR1": iconIng1,
    "SPEC1": iconSpec1,
    "SPEC2": iconSpec2,
    "SPEC3": iconSpec3,
    "SPEC4": iconSpec4,
    "SPEC5": iconSpec5,
    "PR1-1": iconToken,

}
function Inventory() {
    const [username] = useState(() => sessionStorage.getItem("username"));
    const [items, setItems] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [parcels, setParcels] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [gems, setGems] = useState([]);

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.get(`/api/inventory/getItems?username=${username}`);
            if (response.data.success) {
                setItems(response.data.items);
                setIngredients(response.data.ingredients);
                setParcels(response.data.parcels);
                setEquipments(response.data.equipments);
                setGems(response.data.gems);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };
    // Fetch inventory data
    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className="inventoryScreen">
            <ProfileBar />
            <div className="inventoryHeader">Inventory</div>
            <div className="inventoryCard">
                <h3>Consumables (Region 1)</h3>
                <div className="inventoryCategory">
                    {items.map((item, index) => (
                        <div key={index} className="inventory-item">
                            <img src={icons[item.icon]} alt={item.name} className="item-icon" />
                            <div className="item-info">
                                <span className="item-name">{item.name}</span>
                                <span className="item-quantity">x{item.quantity}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="inventoryCard">
                <h3>Ingredients (Region 1)</h3>
                <div className="inventoryCategory">
                    {ingredients.map((item, index) => (
                        <div key={index} className="inventory-item">
                            <img src={icons[item.icon]} alt={item.name} className="item-icon" />
                            <div className="item-info">
                                <span className="item-name">{item.name}</span>
                                <span className="item-quantity">x{item.quantity}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="inventoryCard">
                <h3>Parcels (Region 1)</h3>
                <div className="inventoryCategory">
                    {parcels.map((item, index) => (
                        <div key={index} className="inventory-item">
                            <img src={icons[item.icon]} alt={item.name} className="item-icon" />
                            <div className="item-info">
                                <span className="item-name">{item.name}</span>
                                <span className="item-quantity">x{item.quantity}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="inventoryCard">
                <h3>Equipments</h3>
                <div className="inventoryCategory">
                    {equipments.map((item, index) => (
                        <div key={index} className="inventory-item">
                            <img src={icons[item.icon]} alt={item.name} className="item-icon" />
                            <div className="item-info">
                                <span className="item-name">{item.name}</span>
                                <span className="item-quantity">x{item.quantity}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="inventoryCard">
                <h3>Gems</h3>
                <div className="inventoryCategory">
                    {gems.map((item, index) => (
                        <div key={index} className="inventory-item">
                            <img src={icons[item.icon]} alt={item.name} className="item-icon" />
                            <div className="item-info">
                                <span className="item-name">{item.name}</span>
                                <span className="item-quantity">x{item.quantity}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <NavBar />
        </div>
    );
}

export default Inventory;
