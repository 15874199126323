import './guide.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';

import { useEffect, useState } from 'react';
import axios from 'axios';

function Adventure() {
    let regionNames = ["Unavailable", "1: Singapore"];
    let zoneNames = ["Unavailable", "The Clucky Land (Zone 1)"];

    // Player Info
    const [username] = useState(() => {
        return sessionStorage.getItem("username");
    });

    return (
        <div className="adventureScreen">
            <ProfileBar />
            <div className="adventureHeader">Welcome Guide</div>
            <div className="adventureStatusCard">
                <h3>Storyline</h3>
                <div className="statusInfoText">You found an abandoned food truck along the street and you became the next food truck owner.</div>
                <div className="statusInfoText">You then refurbished the truck and start to wonder what you should do with no starting capital.</div>
                <div className="statusInfoText">Your dream is to become the richest and having the most reputable food truck in the region ever!</div>
            </div>
            <div className="adventureStatusCard">
                <h3>Your Tasks</h3>
                <div className="statusInfoText">To become the richest and having the most reputable food truck, you will need to serve many customers to gain reputation while gaining profits.</div>
                <div className="statusInfoText">However, you do not have much resources to cook up dishes to serve them.</div>
                <div className="statusInfoText">You should start to farm ingredients by yourself through Adventures.</div>
            </div>
            <div className="adventureStatusCard">
                <h3>Adventures</h3>
                <div className="statusInfoText">Adventures are very important in your journey to success.</div>
                <div className="statusInfoText">You should always be farming in zones to farm ingredients while you are busy with life.</div>
                <div className="statusInfoText">However, do come back to claim your rewards as you will get tired after farming for a period of time.</div>
                <div className="statusInfoText">Different Zones comes with different difficulty and you should always keep your equipments in top notch to deal with these enemies.</div>
            </div>
            <div className="adventureStatusCard">
                <h3>Equipments</h3>
                <div className="statusInfoText">Equipments are always part of your journey to success.</div>
                <div className="statusInfoText">You should always be upgrading equipments to improve your damage or your health in Adventures or in Battles.</div>
                <div className="statusInfoText">You will receive equipments and gems materials from parcels or events.</div>
                <div className="statusInfoText">You can obtain Parcels by either farming and clearing enemies from Adventures and Battles or purchase them with cash or gold from the Supermarket too!</div>
            </div>
            <div className="adventureStatusCard">
                <h3>Battles</h3>
                <div className="statusInfoText">Battles are third most important in your journey to success.</div>
                <div className="statusInfoText">You should always be completing dungeons as dungeons provide you with special ingredients that is required to prepare the dishes to serve.</div>
                <div className="statusInfoText">Dungeons require tickets to enter which can be obtainable through Adventure farming.</div>
                <div className="statusInfoText">Dungeons runs on a turn based system where you and the enemy will decide on a move and both of you deal damage at the same time.</div>
                <div className="statusInfoText">Dungeons uses your elemental gem damages when executing moves. Focusing on one gem for damage may not be the best idea as you should try to even out the damages for easier dungeons.</div>
                <div className="statusInfoText">Take note: Water beats Fire, Fire beats Earth, Earth beats Wind and Wind beats Water. You will deal more or receive more damage depending on the Strength and Weaknesses.</div>
            </div>
            <div className="adventureStatusCard">
                <h3>Message from Developer</h3>
                <div className="statusInfoText">I understand this guide is quite long but don't worry, this guide will always be available. You can access this guide at anytime from the Home Page.</div>
                <div className="statusInfoText">I wish you all the best in your food truck journey and I hope to see you on the top of the leaderboards!</div>
            </div>
            <NavBar />
        </div >
    );
}

export default Adventure;
